import { useState, useEffect, Fragment, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { useLocation, useHistory } from 'react-router-dom';

import { useApi } from 'hooks/useApi';
import { useData } from 'hooks/useData';
import { useAuth } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { useSocket } from 'hooks/useSocket';

import { pair } from 'helpers/pair';

import classNames from 'classnames';
import moment from 'moment';

import UserAvatar from 'components/user-avatar';
import ChannelIcon from 'components/channel-icon';
import NewChannel from 'components/new-channel';
import Messages from 'components/messages';
import ComposeMessage from 'components/compose-message';
import ChannelSettings from 'components/channel-settings';
import DateRange from 'components/date-range';

import 'animate.css';



const ViewChat = () => {
  const api = useApi();
  const auth = useAuth();
  const data = useData();
  const query = useQuery();
  const socket = useSocket();
  const history = useHistory();
  const location = useLocation();

  const [showChannels, setShowChannels] = useState(true);
  const [showDirectSessions, setShowDirectSessions] = useState(true);

  const [showNewChannel, setShowNewChannel] = useState(false);
  const [showChannelSettings, setShowChannelSettings] = useState(false);

  const [messagesModuleName, setMessagesModuleName] = useState('');
  const [messagesModuleId, setMessagesModuleId] = useState(0);
  const [messagesTitle, setMessagesTitle] = useState('');
  const [messagesComposeDisabled, setMessagesComposeDisabled] = useState(false);

  const [showArchivedChannels, setShowArchivedChannels] = useState(false);
  
  const [messagesDateRange, setMessagesDateRange] = useState({
    startDate: moment().subtract(1, 'month').toISOString(),
    endDate: moment().toISOString()
  });

  const [messagesHighlightMessageId, setMessagesHighlightMessageId] = useState();

  const [messagesScrollAtBottom, setMessagesScrollAtBottom] = useState(true);

  const messagesContainer = useRef(null);
  const messagesBeginRef = useRef(null);
  const messagesEndRef = useRef(null);


  const getMessagesTitle = (moduleName, moduleId) => {
    if (moduleName.toLowerCase() == 'channel') {
      const channel = data.channels.find((channel) => {
        return (String(channel.id) == String(moduleId));
      });

      if (channel) return `# ${channel.title}`;
    }

    if (moduleName.toLowerCase() == 'direct') {
      const moduleIdSegments = String(moduleId).split(':');

      if (moduleIdSegments.length == 2) {
        const userId = (String(moduleIdSegments[0]) == String(auth.user.id)) ? moduleIdSegments[1] : moduleIdSegments[0];

        const user = data.users.find((user) => {
          return (String(user.id) == String(userId));
        });
  
        if (user) return `Direct messages with ${user.firstName} ${user.lastName}`;
      }
    }

    return '';
  }


  const getMessagesComposeDisabled = (moduleName, moduleId) => {
    if (moduleName.toLowerCase() === 'channel') {
      const channel = data.channels.find((channel) => channel.id == moduleId);

      if (channel && channel.archived) {
        return true;
      }
    }

    return false;
  }


  const setMessagesModule = async (moduleName, moduleId) => {
    setMessagesModuleName(moduleName);
    setMessagesModuleId(moduleId);
    setMessagesTitle(getMessagesTitle(moduleName, moduleId));
    setMessagesComposeDisabled(getMessagesComposeDisabled(moduleName, moduleId));

    data.clearUserUnreadMessages(moduleName, moduleId);
  };


  const selectModule = (moduleName, moduleId) => {
    const params = {
      moduleName: moduleName,
      moduleId: moduleId
    };

    const queryString = new URLSearchParams(params).toString();

    history.push(`/chat?${queryString}`);
  }


  const filteredChannels = () => {
    return data.channels.filter((channel) => {
      const channelUserIds = channel.channelUsers.map((channelUser) => {
        return channelUser.userId;
      });

      const userAssigned = (channelUserIds.indexOf(auth.user.id) >= 0);

      if (userAssigned) {
        if (channel.archived && !showArchivedChannels) return false;

        return true;
      }

      return false;
    });
  };


  const filteredUsers = () => {
    return data.users.filter((user) => {
      if (user.disabled) {
        return false;
      }

      return true;
    }).sort((userA, userB) => {
      return (userA.firstName < userB.firstName) ? -1 : 1;
    });
  };


  const scrollToTop = (behavior = 'auto') => {
    messagesBeginRef.current?.scrollIntoView({ behavior });
  };

  const scrollToBottom = (behavior = 'auto') => {
    messagesEndRef.current?.scrollIntoView({ behavior });
  };


  const messagesOnScroll = (event) => {
    const scrollHeight = messagesContainer.current?.scrollHeight;
    const scrollTop = messagesContainer.current?.scrollTop;
    const clientHeight = messagesContainer.current?.clientHeight;

    setMessagesScrollAtBottom(scrollHeight - scrollTop == clientHeight);
  };


  const messageSent = (message) => {

  };


  const getUnreadMessageCount = (moduleName, moduleId) => {
    return data.userUnreadMessages.filter((userUnreadMessage) => {
      return (userUnreadMessage.moduleName.toLowerCase() == moduleName && userUnreadMessage.moduleId == moduleId);
    }).length;
  };


  useEffect(() => {
    if (messagesScrollAtBottom) {    
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  }, [data.messages]);


  useEffect(() => {
    if (location.pathname.toLowerCase() == '/chat') {
      const moduleName = query.get('moduleName');
      const moduleId = query.get('moduleId');
      const messageId = query.get('messageId');
      const startDate = query.get('startDate');
      const endDate = query.get('endDate');

      if (moduleName && moduleId) {
        setMessagesModule(moduleName, moduleId);
      }

      if (startDate && endDate) {
        setMessagesDateRange({
          startDate: moment(startDate).toISOString(),
          endDate: moment(endDate).toISOString()
        });
      } else {
        setMessagesDateRange({
          startDate: moment().subtract(1, 'month').toISOString(),
          endDate: moment().toISOString()
        });
      }

      if (messageId) {
        setMessagesHighlightMessageId(messageId);
      } else {
        setMessagesHighlightMessageId(null);
      }
    }
  }, [location]);


  return (
    <Fragment>
      <div className="flex h-full">
        <div className="overflow-y-auto flex-shrink-0" style={{ width: '320px' }}>
          <div className="border-b border-gray-200 dark:border-gray-900">
            <div className="flex">
              <button type="button" className="flex items-center gap-x-3 w-full p-4 text-left font-bold text-sm text-gray-700 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-900 dark:hover:text-white" onClick={() => setShowChannels(!showChannels)}>
                <span className={classNames('inline-block transform transition-all text-primary', { 'rotate-90': showChannels })}><FontAwesomeIcon icon={['far', 'angle-right']} fixedWidth size="lg" /></span>
                <span className="flex-1">Gab Sessions</span>
              </button>

              <button title="Show or hide archived gab sessions" className={classNames('px-3 hover:bg-gray-50', { 'text-gray-400' : !showArchivedChannels, 'text-primary' : showArchivedChannels })} onClick={() => setShowArchivedChannels(!showArchivedChannels)}>
                <FontAwesomeIcon icon={['far', 'box-archive']} />
              </button>
            </div>

            <div className={classNames({ 'hidden': !showChannels })}>
              {filteredChannels().map((channel) => (
                <button
                  key={channel.id}
                  type="button"
                  className={classNames('w-full px-4 py-2 text-left flex items-center hover:bg-gray-50 dark:text-gray-300 dark:hover:bg-gray-900 dark:hover:text-white', { 'bg-gray-100 font-bold': messagesModuleName == 'channel' && messagesModuleId == channel.id })}
                  onClick={() => selectModule('channel', channel.id)}>

                  <div>
                    <ChannelIcon name={channel.title} className="w-10 h-10 bg-white border-2 border-white shadow" />
                  </div>

                  <div className="px-4 flex-1">
                    # {channel.title}
                  </div>

                  {(getUnreadMessageCount('channel', channel.id) > 0) && (
                    <div className="bg-primary text-white inline-block w-6 h-6 rounded-full text-center shadow border-2 border-white text-sm font-bold">
                      {getUnreadMessageCount('channel', channel.id)}
                    </div>
                  )}
                </button>
              ))}

              <button type="button" className="block w-full py-4 px-5 text-left dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-900 dark:hover:text-white" onClick={() => setShowNewChannel(true)}>
                <span className="inline-block bg-primary text-white w-8 h-8 rounded text-center pt-1 align-middle mr-3">
                  <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth size="lg" />
                </span>

                New Gab Session
              </button>
            </div>
          </div>

          <div>
            <button type="button" className="block w-full p-4 text-left font-bold text-sm hover:bg-gray-50 text-gray-700 dark:text-gray-400 dark:hover:bg-gray-900 dark:hover:text-white" onClick={() => setShowDirectSessions(!showDirectSessions)}>
              <span className={classNames('inline-block transform transition-all text-primary', { 'rotate-90': showDirectSessions })}><FontAwesomeIcon icon={['far', 'angle-right']} fixedWidth size="lg" /></span> <span>Direct Gabs</span>
            </button>

            <div className={classNames({ 'hidden': !showDirectSessions })}>
              {filteredUsers().map((user) => (
                <button
                  key={user.id}
                  type="button"
                  className={classNames('w-full px-4 py-2 text-left flex items-center hover:bg-gray-50 dark:text-gray-300 dark:hover:bg-gray-900 dark:hover:text-white', { 'bg-gray-100 font-bold': messagesModuleName == 'direct' && messagesModuleId == pair(user.id, auth.user.id) })}
                  onClick={() => selectModule('direct', pair(user.id, auth.user.id))}>

                  <div>
                    <UserAvatar user={user} showStatus={false} className="w-10 h-10 rounded-full border-2 border-white shadow" />
                  </div>

                  <div className="px-4 flex-1">
                    {user.firstName} {user.lastName}
                  </div>

                  {(getUnreadMessageCount('direct', pair(user.id, auth.user.id)) > 0) && (
                    <div className="bg-primary text-white inline-block w-6 h-6 rounded-full text-center shadow border-2 border-white text-sm font-bold">
                      {getUnreadMessageCount('direct', pair(user.id, auth.user.id))}
                    </div>
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="flex-grow border-l border-gray-200 h-full dark:border-gray-900">
          {(messagesModuleName && messagesModuleId) && (
            <Fragment>
              <div className="flex flex-col h-full">
                <div className="p-2 shadow flex items-center gap-x-4">
                  <div>
                    {(messagesModuleName.toLowerCase() == 'channel') && (
                      <button type="button" className="py-1 px-3 text-gray-700 rounded hover:bg-gray-50 hover:text-gray-900" onClick={() => setShowChannelSettings(true)}>
                        <span className="text-lg font-semibold">{messagesTitle}</span>

                        <FontAwesomeIcon icon={['fas', 'angle-down']} className="ml-4 text-gray-500" size="sm" />
                      </button>
                    )}

                    {(messagesModuleName.toLowerCase() == 'direct') && (
                      <span className="inline-block py-1 px-3 text-gray-700 text-lg font-semibold">
                        {messagesTitle}
                      </span>
                    )}
                  </div>

                  <div className="flex-1">

                  </div>

                  <div>
                    <DateRange value={messagesDateRange} setValue={setMessagesDateRange} />
                  </div>

                  <div>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="rounded-md bg-white px-3 py-2 text-sm font-semibold hover:bg-gray-50">
                          Quick Scroll
                          <FontAwesomeIcon icon={['far', 'computer-mouse']} className="ml-2" />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              <button className="block w-full text-left px-4 py-2 hover:bg-gray-50" onClick={() => scrollToTop('smooth')}>
                                <FontAwesomeIcon icon={['far', 'arrow-up']} className="mr-2" />To the top
                              </button>
                            </Menu.Item>

                            <Menu.Item>
                              <button className="block w-full text-left px-4 py-2 hover:bg-gray-50" onClick={() => scrollToBottom('smooth')}>
                                <FontAwesomeIcon icon={['far', 'arrow-down']} className="mr-2" />To the bottom
                              </button>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>

                <div className="flex-1 overflow-y-auto p-5 relative" ref={messagesContainer} onScroll={messagesOnScroll}>
                  <div ref={messagesBeginRef} />
                    <Messages moduleName={messagesModuleName} moduleId={messagesModuleId} title={messagesTitle} dateRange={messagesDateRange} setDateRange={setMessagesDateRange} highlightMessageId={messagesHighlightMessageId} />
                  <div ref={messagesEndRef} />
                </div>

                <div className="p-5 bg-gray-50">
                  <ComposeMessage moduleName={messagesModuleName} moduleId={messagesModuleId} title={messagesTitle} messageSent={messageSent} disabled={messagesComposeDisabled}></ComposeMessage>
                </div>
              </div>

              {(messagesModuleName.toLowerCase() == 'channel') && (
                <ChannelSettings channelId={messagesModuleId} show={showChannelSettings} setShow={setShowChannelSettings} />
              )}
            </Fragment>
          )}
        </div>
      </div>

      <NewChannel show={showNewChannel} setShow={setShowNewChannel} />
    </Fragment>
  );
};


export default ViewChat;